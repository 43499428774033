import React, { useReducer } from "react"; // eslint-disable-line no-unused-vars
import * as consts from "../consts";
import { State } from "./state";

const initialState = {
  alerts: [],
  busy: false,
  profile: null,
  year: "2022",
  challenges: [],
  challenge: null,
  leaderboard: [],
  bonusChallenges: [],
  bonusChallenge: null,
};

function reducer(state, action) {
  switch (action.type) {
    case consts.ACTION_ADD_ALERT:
      return { ...state, alerts: [...state.alerts, action.payload] };
    case consts.ACTION_DISMISS_ALERT: {
      let updatedAlerts = state.alerts.slice();
      updatedAlerts.shift();
      return {
        ...state,
        alerts: updatedAlerts,
      };
    }
    case consts.ACTION_SET_BUSY:
      return { ...state, busy: action.payload };
    case consts.ACTION_SET_CHALLENGE:
      return { ...state, challenge: action.payload };
    case consts.ACTION_SET_BONUS_CHALLENGE:
      return { ...state, bonusChallenge: action.payload };
    case consts.ACTION_SET_LEADERBOARD:
      return { ...state, leaderboard: action.payload };
    case consts.ACTION_INITIALIZE_CHALLENGES:
      return {
        ...state,
        challenges: action.payload,
      };
    case consts.ACTION_INITIALIZE_BONUS_CHALLENGES:
      return {
        ...state,
        bonusChallenges: action.payload,
      };
    default:
      return state;
  }
}

export function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <State.Provider value={value}>{props.children}</State.Provider>;
}
