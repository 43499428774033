import React from "react"; // eslint-disable-line no-unused-vars
import { Grid, Button, Typography, Hidden } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const renderLoginButton = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            color="primary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
              });
            }}
          >
            Sign In
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth={true}
            color="secondary"
            variant="outlined"
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: window.location.pathname },
                screen_hint: "signup",
              });
            }}
          >
            Sign up
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (isLoading || isAuthenticated) {
    return null;
  } else {
    return (
      <Grid item container xs={12} spacing={2}>
        <Hidden smUp>{renderLoginButton()}</Hidden>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: "1.2rem",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            EVERY MONTH A NEW FITNESS CHALLENGE IS OFFERED
          </Typography>
          <Typography variant="h2" align="center" sx={{ fontSize: "1rem" }}>
            WHY SIGN UP?
          </Typography>
          <Typography variant="caption" component="div">
            <ul>
              <li>
                Training consistently helps increase and build your level of
                fitness
              </li>
              <li>
                All challenges can be done with no gym or special equipment
              </li>
              <li>
                Tracking daily keeps you accountable and can help build a daily
                fitness routine
              </li>
              <li>Set a daily, weekly or monthly goal and go after it</li>
              <li>
                Have your friends, family and coworkers sign up to have an
                additional accountability partner
              </li>
              <li>It is FREE and your body will thank you!</li>
            </ul>
          </Typography>
        </Grid>
        {renderLoginButton()}
      </Grid>
    );
  }
}
