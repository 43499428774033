import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  FitnessCenter as LiftIcon,
  DirectionsRun as RunIcon,
  DirectionsWalk as WalkIcon,
  Pool as SwimIcon,
  DirectionsBike as BikeIcon,
  Rowing as RowIcon,
  Hiking as HikeIcon,
  AccessibilityNew as BurpeeIcon,
  AirlineSeatLegroomNormal as WallSitIcon,
  AirlineSeatReclineExtra as SitupIcon,
  Park as PlankIcon,
  FitnessCenter as SquatIcon,
  Landscape as MountainClimberIcon,
  AirlineSeatFlatAngled as PushupIcon,
  SportsMartialArts as LungeIcon,
  SelfImprovement as YogaIcon,
  NoDrinks as DrinkIcon,
  NoFood as FoodIcon,
  SwitchAccessShortcutAdd as OtherIcon,
  Delete as DeleteIcon,
  Loyalty as BonusIcon,
} from "@mui/icons-material";
import * as consts from "../../consts";
import { useAuth0 } from "@auth0/auth0-react";
import { State } from "../../store/state";
import { removeEffort, saveEffort, setBusy } from "../../store/actions";
import EffortRecorder from "./effortRecorder";
import { green, red, pink } from "@mui/material/colors";

export default function ChallengeSelector(props) {
  const { state, dispatch } = useContext(State);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedEffort, setSelectedEffort] = useState(null);
  const [confirmDeleteEffort, setConfirmDeleteEffort] = useState(null);
  const [hideDialog, setHideDialog] = useState(true);

  const ActivityIcon = (props) => {
    const iconStyle = {
      fontSize: 18,
      color: green[500],
    };
    switch (props.activity) {
      case consts.ACTIVITY_SWIM: {
        return <SwimIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_BIKE: {
        return <BikeIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_WALK: {
        return <WalkIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_HIKE: {
        return <HikeIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_RUN: {
        return <RunIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_ROW: {
        return <RowIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_LIFT: {
        return <LiftIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_BURPEE: {
        return <BurpeeIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_WALLSITES: {
        return <WallSitIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_PLANK: {
        return <PlankIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_SITUPS: {
        return <SitupIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_PUSHUPS: {
        return <PushupIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_SQUATS: {
        return <SquatIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_MOUNTAINCLIMBERS: {
        return <MountainClimberIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_LUNGES: {
        return <LungeIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_YOGA: {
        return <YogaIcon sx={iconStyle} />;
      }
      case consts.ACTIVITY_OTHER: {
        return <OtherIcon sx={iconStyle} />;
      }
    }
  };

  const PenaltyIcon = (props) => {
    const iconStyle = {
      fontSize: 18,
      color: red[500],
    };
    switch (props.penalty) {
      case consts.PENALTY_DRINK: {
        return <DrinkIcon sx={iconStyle} />;
      }
      case consts.PENALTY_FOOD: {
        return <FoodIcon sx={iconStyle} />;
      }
      case consts.PENALTY_OTHER: {
        return <OtherIcon sx={iconStyle} />;
      }
    }
  };

  const Total = (props) => {
    let total = 0;
    props.efforts.map((effort) => {
      total += effort.value;
    });
    return (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          Total Effort: {total.toFixed(2)} {props.metric}
        </Typography>
      </Grid>
    );
  };

  const ConfirmDeleteDialog = () => {
    return (
      <Dialog
        open={confirmDeleteEffort ? true : false}
        onClose={() => setConfirmDeleteEffort(null)}
      >
        <DialogTitle>Delete Effort</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this effort?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDeleteEffort(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setHideDialog(true);
              setBusy(true, dispatch);
              removeEffort(
                state.year,
                state.challenge.id,
                confirmDeleteEffort,
                getAccessTokenSilently,
                dispatch
              ).then(() => {
                setConfirmDeleteEffort(null);
                setBusy(false, dispatch);
              });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Grid item container xs={12} spacing={2}>
      {!hideDialog && <ConfirmDeleteDialog />}
      {!hideDialog && state.challenge.active && selectedEffort && (
        <EffortRecorder
          penalties={state.challenge.penalties}
          activities={state.challenge.activities}
          bonuses={state.challenge.bonuses}
          metric={state.challenge.metric}
          efforts={state.challenge.myEfforts}
          startDate={state.challenge.startDate}
          endDate={state.challenge.endDate}
          selectedEffort={selectedEffort}
          onCancel={() => setSelectedEffort(null)}
          onSave={(effort) => {
            setHideDialog(true);
            setBusy(true, dispatch);
            saveEffort(
              state.year,
              state.challenge.id,
              effort.date,
              effort.activities,
              effort.penalties,
              effort.value,
              effort.useBonus,
              getAccessTokenSilently,
              dispatch
            ).then(() => {
              setSelectedEffort(null);
              setBusy(false, dispatch);
            });
          }}
          onDelete={() => {
            setHideDialog(true);
            setBusy(true, dispatch);
            removeEffort(
              state.year,
              state.challenge.id,
              selectedEffort,
              getAccessTokenSilently,
              dispatch
            ).then(() => {
              setSelectedEffort(null);
              setBusy(false, dispatch);
            });
          }}
        />
      )}
      <Total
        efforts={state.challenge.myEfforts}
        metric={state.challenge.metric}
      />
      {state.challenge.active && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth={true}
            onClick={() => {
              setSelectedEffort({
                date: new Date(),
                activities:
                  state.challenge.activities.length == 1 &&
                  state.challenge.activities[0] != consts.ACTIVITY_ALL
                    ? [state.challenge.activities[0]]
                    : [],
                penalties: [],
                value: 0,
                useBonus: false,
              });
              setHideDialog(false);
            }}
          >
            Add Effort
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell component="th">Activities</TableCell>
                <TableCell component="th" align="right">
                  {state.challenge.metric}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.challenge?.myEfforts?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    if (state.challenge.active) {
                      setHideDialog(false);
                      setSelectedEffort(row);
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    {new Date(row.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell scope="row">
                    {row.activities.sort().map((activity) => {
                      return (
                        <ActivityIcon activity={activity} key={activity} />
                      );
                    })}
                    {row.penalties.map((penalty) => {
                      return <PenaltyIcon penalty={penalty} key={penalty} />;
                    })}
                    {row.useBonus && (
                      <BonusIcon
                        sx={{
                          fontSize: 18,
                          color: pink[500],
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                  <TableCell align="right">
                    {state.challenge.active && (
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setHideDialog(false);
                          setConfirmDeleteEffort(row);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
