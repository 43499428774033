import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Grid, Typography, Tab, Tabs, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { getChallenge, setBusy } from "../../store/actions";
import { State } from "../../store/state";
import Rankings from "../controls/rankings";
import Me from "../controls/me";

export default function Challenge(props) {
  const { year, challengeID } = useParams();
  const { state, dispatch } = useContext(State);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedTab, setSelectedTab] = useState("instructions");

  useEffect(() => {
    setBusy(true, dispatch);
    getChallenge(year, challengeID, getAccessTokenSilently, dispatch).then(
      (challenge) => {
        setBusy(false, dispatch);
      }
    );
  }, []);

  if (state.challenge) {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "16px",
            }}
          >
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              onChange={(event, value) => {
                setSelectedTab(value);
              }}
            >
              <Tab label="Instructions" value="instructions" />
              <Tab label="Rankings" value="rankings" />
              <Tab label="Me" value="me" />
            </Tabs>
          </Box>
        </Grid>
        {selectedTab === "instructions" && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="body1">{state.challenge.summary}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: state.challenge.instructions,
                  }}
                />
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {selectedTab === "rankings" && (
          <Grid item xs={12}>
            <Rankings />
          </Grid>
        )}
        {selectedTab === "me" && (
          <Grid item xs={12}>
            <Me />
          </Grid>
        )}
      </Grid>
    );
  } else {
    return null;
  }
}
