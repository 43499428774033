import * as consts from "../consts";
import axios from "axios";

function handleError(exception, message, dispatch) {
  if (process.env.NODE_ENV === "development") {
    console.log("SF-CLIENT:", exception);
  }
  if (message && dispatch) {
    addAlert(
      {
        message: message,
        type: consts.ALERT_TYPE_ERROR,
      },
      dispatch
    );
  }
}

function handleSuccess(response, message, dispatch) {
  if (process.env.NODE_ENV === "development") {
    console.log("SF-CLIENT:", response);
  }
  if (message && dispatch) {
    addAlert(
      {
        message: message,
        type: consts.ALERT_TYPE_SUCCESS,
      },
      dispatch
    );
  }
}

function getHTTPHeaders(getAccessTokenSilently) {
  return new Promise((resolve) => {
    if (getAccessTokenSilently) {
      getAccessTokenSilently()
        .then((token) => {
          let options = token
            ? { headers: { Authorization: `Bearer ${token}` } }
            : {};
          resolve(options);
        })
        .catch(() => {
          resolve({});
        });
    } else {
      resolve({});
    }
  });
}

export function addAlert(alert, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_ADD_ALERT,
      payload: alert,
    });
    resolve();
  });
}

export function dismissAlert(dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_DISMISS_ALERT,
    });
    resolve();
  });
}

export function setBusy(busy, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_SET_BUSY,
      payload: busy,
    });
    resolve();
  });
}

export function saveEffort(
  year,
  challengeID,
  date,
  activities,
  penalties,
  value,
  useBonus,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = {
      activities: activities,
      penalties: penalties,
      date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      value: value,
      useBonus: useBonus,
    };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/challenges/${year}/${challengeID}`,
          data,
          options
        )
        .then((response) => {
          let effort = response.data;
          getChallenge(
            year,
            challengeID,
            getAccessTokenSilently,
            dispatch
          ).then(() => {
            handleSuccess(response, `Successfully added effort`, dispatch);
            resolve(effort);
          });
        })
        .catch((exception) => {
          handleError(exception, `Unable to add effort`, dispatch);
          reject(exception);
        });
    });
  });
}

export function removeEffort(
  year,
  challengeID,
  effort,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .delete(
          `${consts.SERVICE_URL}/challenges/${year}/${challengeID}/${effort.id}`,
          options
        )
        .then((response) => {
          let effort = response.data;
          getChallenge(
            year,
            challengeID,
            getAccessTokenSilently,
            dispatch
          ).then(() => {
            handleSuccess(response, `Successfully removed effort`, dispatch);
            resolve(effort);
          });
        })
        .catch((exception) => {
          handleError(exception, `Unable to remove effort`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getChallenges(year, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/Challenges/${year}`, options)
        .then((response) => {
          let challenges = [...response.data].sort(
            (a, b) => a.sortOrder - b.sortOrder
          );
          dispatch({
            type: consts.ACTION_INITIALIZE_CHALLENGES,
            payload: challenges,
          });
          resolve(challenges);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve challenges`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getChallenge(
  year,
  challengeID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/Challenges/${year}/${challengeID}`, options)
        .then((response) => {
          let challenge = response.data;

          challenge.myEfforts = [...challenge.myEfforts].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );

          challenge.rankings = [...challenge.rankings].sort((a, b) => {
            let testPoints = b.points - a.points;
            let testTotalValue = b.totalValue - a.totalValue;
            return testPoints == 0 ? testTotalValue : testPoints;
          });

          dispatch({
            type: consts.ACTION_SET_CHALLENGE,
            payload: challenge,
          });
          resolve(challenge);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve challenge`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getLeaderboard(getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/Leaderboard`, options)
        .then((response) => {
          let leaderboard = response.data;

          leaderboard = [...leaderboard].sort((a, b) => {
            let testPoints = b.points - a.points;
            let testLevel = b.level - a.level;
            return testLevel == 0 ? testPoints : testLevel;
          });

          dispatch({
            type: consts.ACTION_SET_LEADERBOARD,
            payload: leaderboard,
          });
          resolve(leaderboard);
        })
        .catch((exception) => {
          handleError(exception, `Unable to retrieve leaderboard`, dispatch);
          reject(exception);
        });
    });
  });
}

export function getBonusChallenges(year, getAccessTokenSilently, dispatch) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(`${consts.SERVICE_URL}/Bonuses/${year}`, options)
        .then((response) => {
          let bonusChallenges = [...response.data];
          dispatch({
            type: consts.ACTION_INITIALIZE_BONUS_CHALLENGES,
            payload: bonusChallenges,
          });
          resolve(bonusChallenges);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve bonus challenges`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function getBonusChallenge(
  year,
  bonusChallengeID,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .get(
          `${consts.SERVICE_URL}/Bonuses/${year}/${bonusChallengeID}`,
          options
        )
        .then((response) => {
          let bonusChallenge = response.data;
          dispatch({
            type: consts.ACTION_SET_BONUS_CHALLENGE,
            payload: bonusChallenge,
          });
          resolve(bonusChallenge);
        })
        .catch((exception) => {
          handleError(
            exception,
            `Unable to retrieve bonus challenge`,
            dispatch
          );
          reject(exception);
        });
    });
  });
}

export function saveBonus(
  year,
  bonusChallengeID,
  comments,
  link,
  photo,
  getAccessTokenSilently,
  dispatch
) {
  return new Promise((resolve, reject) => {
    let data = {
      comments: comments,
      link: link,
      photo: photo,
    };
    getHTTPHeaders(getAccessTokenSilently).then((options) => {
      axios
        .post(
          `${consts.SERVICE_URL}/bonuses/${year}/${bonusChallengeID}`,
          data,
          options
        )
        .then((response) => {
          let effort = response.data;
          getBonusChallenge(
            year,
            bonusChallengeID,
            getAccessTokenSilently,
            dispatch
          ).then(() => {
            handleSuccess(response, `Successfully added bonus`, dispatch);
            resolve(effort);
          });
        })
        .catch((exception) => {
          handleError(exception, `Unable to add bonus`, dispatch);
          reject(exception);
        });
    });
  });
}
