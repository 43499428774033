import React from "react"; // eslint-disable-line no-unused-vars
import { AppBar, Toolbar } from "@mui/material";

export default function Header(props) {
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <img
          src="https://app.squamish.fit/logo.png"
          alt="Squamish Fit Logo"
          style={{ height: "30px" }}
        />
      </Toolbar>
    </AppBar>
  );
}
