import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { State } from "../../store/state";

export default function Rankings(props) {
  const { state, dispatch } = useContext(State);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">&nbsp;</TableCell>
            <TableCell component="th">Athlete</TableCell>
            <TableCell component="th" align="right">
              {state.challenge.metric}
            </TableCell>
            <TableCell component="th" align="right">
              Points
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.challenge?.rankings?.map((row, index) => (
            <TableRow
              key={row.username}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.username}</TableCell>
              <TableCell align="right">{row.totalValue}</TableCell>
              <TableCell align="right">{row.points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
