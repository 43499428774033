import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import * as consts from "../../consts";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";

export default function EffortRecorder(props) {
  const [date, setDate] = useState(new Date(props.selectedEffort.date));
  const [selectedActivities, setSelectedActivities] = useState(
    props.selectedEffort.activities
  );
  const [availableActivities, setAvailableActivities] = useState([]);
  const [value, setValue] = useState(props.selectedEffort.value);
  const [useBonus, setUseBonus] = useState(props.selectedEffort.useBonus);
  const [selectedPenalties, setSelectedPenalties] = useState(
    props.selectedEffort.penalties
  );
  const [availablePenalties, setAvailablePenalties] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);

  useEffect(() => {
    let activities = consts.ACTIVITIES.filter((activity) => {
      return (
        props.activities.indexOf(consts.ACTIVITY_ALL) > -1 ||
        props.activities.indexOf(activity.id) > -1
      );
    });
    setAvailableActivities(activities);
  }, [props.activities]);

  useEffect(() => {
    let penalties = consts.PENALTIES.filter((penalty) => {
      return props.penalties.indexOf(penalty.id) > -1;
    });
    setAvailablePenalties(penalties);
  }, [props.penalties]);

  useEffect(() => {
    console.log(date);
    let efforts = props.efforts.filter((effort) => {
      let effortDate = new Date(effort.date);
      return (
        effortDate.getFullYear() == date.getFullYear() &&
        effortDate.getMonth() == date.getMonth() &&
        effortDate.getDate() == date.getDate()
      );
    });
    setShowOverwriteWarning(efforts.length > 0);
  }, [date]);

  const handleSave = () => {
    if (isValid()) {
      props.onSave({
        ...props.selectedEffort,
        date: date,
        activities: selectedActivities,
        value: value,
        penalties: selectedPenalties,
        useBonus: useBonus,
      });
    }
  };

  const isValid = () => {
    return (
      date <= new Date(props.endDate) &&
      date >= new Date(props.startDate) &&
      ((useBonus && !showOverwriteWarning) ||
        (selectedActivities.length > 0 && value))
    );
  };

  return (
    <Dialog open={true} onClose={() => props.onCancel()}>
      <DialogTitle sx={{ paddingBottom: "0px", marginBottom: "-16px" }}>
        Record Effort
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={2} sx={{ mt: 1 }}>
          {props.bonuses.length > 0 && (
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useBonus}
                      onChange={(event) => {
                        setUseBonus(event.target.checked);
                      }}
                    />
                  }
                  labelPlacement="start"
                  label="Use Bonus"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disableFuture
                maxDate={new Date(props.endDate)}
                minDate={new Date(props.startDate)}
                views={["day"]}
                label="Date of effort"
                value={date}
                disabled={props.selectedEffort.id ? true : false}
                onChange={(event) => {
                  setDate(event);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth={true}
                    error={showOverwriteWarning}
                    helperText={
                      showOverwriteWarning
                        ? "We see you've already recorded an effort for this day. If you save this you'll overwrite the existing effort for this day."
                        : ""
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {!useBonus && (
            <React.Fragment>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Activities</InputLabel>
                  <Select
                    multiple={true}
                    value={selectedActivities}
                    onChange={(event) => {
                      setSelectedActivities(
                        typeof event.target.value === "string"
                          ? event.target.value.split(",")
                          : event.target.value
                      );
                    }}
                    input={<OutlinedInput label="Activities" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          let activity = availableActivities.find(
                            (availableActivity) => {
                              return availableActivity.id == value;
                            }
                          );
                          return (
                            <Chip key={activity.id} label={activity.label} />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {availableActivities.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={props.metric}
                  type="number"
                  value={value}
                  fullWidth={true}
                  onChange={(event) => {
                    if (!isNaN(event.target.value)) {
                      setValue(event.target.value);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSave();
                    }
                  }}
                />
              </Grid>
              {availablePenalties.length > 0 && (
                <Grid item xs={12}>
                  <Typography>Penalties</Typography>
                  <FormGroup>
                    {availablePenalties.map((availablePenalty) => {
                      let selected = selectedPenalties.find((penalty) => {
                        return availablePenalty.id == penalty;
                      });
                      return (
                        <FormControlLabel
                          key={availablePenalty.id}
                          control={
                            <Switch
                              value={availablePenalty.id}
                              checked={selected ? true : false}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSelectedPenalties([
                                    ...selectedPenalties,
                                    availablePenalty.id,
                                  ]);
                                } else {
                                  setSelectedPenalties(
                                    selectedPenalties.filter((penalty) => {
                                      return penalty != availablePenalty.id;
                                    })
                                  );
                                }
                              }}
                            />
                          }
                          label={availablePenalty.label}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth={true}
              disabled={!isValid()}
              onClick={() => {
                handleSave();
              }}
            >
              {props.selectedEffort.id || showOverwriteWarning
                ? "Update Effort"
                : "Add Effort"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
