import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { State } from "../../store/state";
import { getLeaderboard, setBusy } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";

export default function Leaderboard(props) {
  const { state, dispatch } = useContext(State);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setBusy(true, dispatch);
    getLeaderboard(getAccessTokenSilently, dispatch).then(() => {
      setBusy(false, dispatch);
    });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell component="th">Athlete</TableCell>
            <TableCell component="th" align="right">
              Level
            </TableCell>
            <TableCell component="th" align="right">
              Points
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.leaderboard.map((row, index) => (
            <TableRow
              key={row.username}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.username}</TableCell>
              <TableCell align="right">{row.level}</TableCell>
              <TableCell align="right">{row.points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
