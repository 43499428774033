import React from "react"; // eslint-disable-line no-unused-vars
import {
  Home as HomeIcon,
  MilitaryTech as ChallengeIcon,
} from "@mui/icons-material";
import Main from "./components/routes/main";
import Challenge from "./components/routes/challenge";
import Bonus from "./components/routes/bonus";
import Leaderboard from "./components/routes/leaderboard";

export const SERVICE_URL = process.env.SERVICE_URL;
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE;
export const AUTH0_CLIENTID = process.env.AUTH0_CLIENTID;
export const AUTH0_SCOPE = "openid profile";

export const ACTION_ADD_ALERT = "ACTION_ADD_ALERT";
export const ACTION_DISMISS_ALERT = "ACTION_DISMISS_ALERT";
export const ACTION_SET_BUSY = "ACTION_SET_BUSY";
export const ACTION_SET_CHALLENGE = "ACTION_SET_CHALLENGE";
export const ACTION_SET_BONUS_CHALLENGE = "ACTION_SET_BONUS_CHALLENGE";
export const ACTION_SET_LEADERBOARD = "ACTION_SET_LEADERBOARD";
export const ACTION_INITIALIZE_CHALLENGES = "ACTION_INITIALIZE_CHALLENGES";
export const ACTION_INITIALIZE_BONUS_CHALLENGES =
  "ACTION_INITIALIZE_BONUS_CHALLENGES";

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";

export const ROUTES = [
  {
    path: "challenges/:year/:challengeID",
    link: "/",
    label: "Challenge",
    element: <Challenge />,
    children: [],
  },
  {
    path: "bonuses/:year/:bonusChallengeID",
    link: "/",
    label: "Bonus Challenge",
    element: <Bonus />,
    children: [],
  },
  {
    path: "leaderboard",
    link: "/",
    label: "Leaderboard",
    element: <Leaderboard />,
    children: [],
  },
  {
    path: "/*",
    link: "/",
    label: "Home",
    element: <Main />,
  },
];

export const ACTIVITY_LIFT = "lift";
export const ACTIVITY_RUN = "run";
export const ACTIVITY_WALK = "walk";
export const ACTIVITY_SWIM = "swim";
export const ACTIVITY_BIKE = "bike";
export const ACTIVITY_ROW = "row";
export const ACTIVITY_HIKE = "hike";
export const ACTIVITY_BURPEE = "burpee";
export const ACTIVITY_WALLSITES = "wallsits";
export const ACTIVITY_PLANK = "plank";
export const ACTIVITY_SITUPS = "situps";
export const ACTIVITY_PUSHUPS = "pushups";
export const ACTIVITY_SQUATS = "squats";
export const ACTIVITY_MOUNTAINCLIMBERS = "mountainclimbers";
export const ACTIVITY_LUNGES = "lunges";
export const ACTIVITY_YOGA = "yoga";
export const ACTIVITY_ALL = "all";
export const ACTIVITY_OTHER = "other";

export const PENALTY_DRINK = "drink";
export const PENALTY_FOOD = "food";
export const PENALTY_OTHER = "other";

export const ACTIVITIES = [
  { id: ACTIVITY_BIKE, label: "Biking" },
  { id: ACTIVITY_BURPEE, label: "Burpee" },
  { id: ACTIVITY_HIKE, label: "Hiking" },
  { id: ACTIVITY_LIFT, label: "Lifting" },
  { id: ACTIVITY_LUNGES, label: "Lunges" },
  { id: ACTIVITY_MOUNTAINCLIMBERS, label: "Mountain Climbers" },
  { id: ACTIVITY_PLANK, label: "Plank" },
  { id: ACTIVITY_PUSHUPS, label: "Push-ups" },
  { id: ACTIVITY_ROW, label: "Rowing" },
  { id: ACTIVITY_RUN, label: "Running" },
  { id: ACTIVITY_SITUPS, label: "Sit-ups" },
  { id: ACTIVITY_SQUATS, label: "Squats" },
  { id: ACTIVITY_SWIM, label: "Swimming" },
  { id: ACTIVITY_WALK, label: "Walking" },
  { id: ACTIVITY_WALLSITES, label: "Wall Sits" },
  { id: ACTIVITY_YOGA, label: "Yoga" },
  { id: ACTIVITY_OTHER, label: "Other" },
];

export const PENALTIES = [
  { id: PENALTY_DRINK, label: "Drink" },
  { id: PENALTY_FOOD, label: "Junk Food" },
  { id: PENALTY_OTHER, label: "Other" },
];
