import React from "react"; // eslint-disable-line no-unused-vars
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import * as consts from "../../consts";

export default function AuthProvider(props) {
  let navigate = useNavigate();
  return (
    <Auth0Provider
      domain={consts.AUTH0_DOMAIN}
      clientId={consts.AUTH0_CLIENTID}
      audience={consts.AUTH0_AUDIENCE}
      scope={consts.AUTH0_SCOPE}
      redirectUri={window.location.origin}
      // cacheLocation="localstorage"
      responseType="id_token token"
      onRedirectCallback={(appState) => {
        navigate(
          appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
        );
      }}
    >
      {props.children}
    </Auth0Provider>
  );
}
