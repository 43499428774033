import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "./components/auth/authProvider";
import { Provider } from "./store/provider";
import App from "./components/app";

if (process.env.NODE_ENV === "development") {
  console.log("SF-CLIENT: Loading");
}

let render = function (app, theme) {
  ReactDOM.render(
    <React.Fragment>
      <Router>
        <AuthProvider>
          <Provider>
            <App theme={theme} />
          </Provider>
        </AuthProvider>
      </Router>
    </React.Fragment>,
    app
  );
};

let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("SF-CLIENT: Initializing React component");
  }
  let app = document.getElementById("sfclient");
  if (app) {
    fetch(process.env.THEME_URL)
      .then((response) => response.json())
      .then((theme) => {
        render(app, theme);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.log("SF-CLIENT: ", error);
        }
        render(app, {});
      });
  } else {
    if (process.env.NODE_ENV === "development") {
      console.log("SF-CLIENT: No element to insert component into");
    }
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("SF-CLIENT: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}
