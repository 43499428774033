import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
  Tab,
  Tabs,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  CardHeader,
  CardMedia,
  Divider,
} from "@mui/material";
import { Link as LinkIcon } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { getBonusChallenge, saveBonus, setBusy } from "../../store/actions";
import { State } from "../../store/state";

export default function Bonus(props) {
  const { year, bonusChallengeID } = useParams();
  const { state, dispatch } = useContext(State);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedTab, setSelectedTab] = useState("instructions");
  const [comments, setComments] = useState("");
  const [link, setLink] = useState("");
  const [photo, setPhoto] = useState("");
  const [complete, setComplete] = useState(true);

  useEffect(() => {
    setBusy(true, dispatch);
    getBonusChallenge(
      year,
      bonusChallengeID,
      getAccessTokenSilently,
      dispatch
    ).then((bonusChallenge) => {
      setBusy(false, dispatch);
    });
  }, []);

  useEffect(() => {
    if (state.bonusChallenge) {
      let myBonus = state.bonusChallenge.bonuses.find((bonus) => {
        return bonus.identity === user.sub;
      });
      setComplete(myBonus ? true : false);
      setComments(myBonus ? myBonus.comments : "");
      setLink(myBonus ? myBonus.link : "");
      setPhoto(myBonus ? myBonus.photo : "");
    }
  }, [state.bonusChallenge]);

  const handleSave = () => {
    if (isValid()) {
      setBusy(true, dispatch);
      saveBonus(
        year,
        bonusChallengeID,
        comments,
        link,
        photo,
        getAccessTokenSilently,
        dispatch
      ).then(() => {
        setBusy(false, dispatch);
      });
    }
  };

  const isValid = () => {
    return true;
  };

  if (state.bonusChallenge) {
    return (
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "16px",
            }}
          >
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              onChange={(event, value) => {
                setSelectedTab(value);
              }}
            >
              <Tab label="Instructions" value="instructions" />
              <Tab label="Entries" value="entries" />
            </Tabs>
          </Box>
        </Grid>
        {selectedTab === "instructions" && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: state.bonusChallenge.description,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        )}
        {selectedTab === "entries" && (
          <Grid item container xs={12} spacing={2}>
            {!complete && (
              <React.Fragment>
                {state.bonusChallenge.enableLink && (
                  <Grid item xs={12}>
                    <TextField
                      label="Link to resource"
                      value={link}
                      helperText={state.bonusChallenge.linkHelperText}
                      fullWidth={true}
                      onChange={(event) => {
                        setLink(event.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleSave();
                        }
                      }}
                    />
                  </Grid>
                )}
                {state.bonusChallenge.enableComments && (
                  <Grid item xs={12}>
                    <TextField
                      label="Comments"
                      value={comments}
                      multiline={true}
                      rows={4}
                      helperText={state.bonusChallenge.commentsHelperText}
                      fullWidth={true}
                      onChange={(event) => {
                        setComments(event.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleSave();
                        }
                      }}
                    />
                  </Grid>
                )}
                {state.bonusChallenge.enablePhoto && (
                  <Grid item xs={12}>
                    <TextField
                      label="Link to photo"
                      value={photo}
                      helperText={state.bonusChallenge.photoHelperText}
                      fullWidth={true}
                      onChange={(event) => {
                        setPhoto(event.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleSave();
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth={true}
                    disabled={!isValid()}
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </React.Fragment>
            )}
            <Grid item container xs={12} spacing={2}>
              {state.bonusChallenge.bonuses.map((bonus, index) => {
                if (bonus.comments || bonus.photo) {
                  return (
                    <Grid item xs={12}>
                      <Card key={index}>
                        <CardHeader
                          action={
                            bonus.link ? (
                              <IconButton
                                onClick={() => {
                                  window.open(bonus.link, "_blank").focus();
                                }}
                              >
                                <LinkIcon />
                              </IconButton>
                            ) : null
                          }
                          title={bonus.username}
                        />
                        <CardActionArea
                          onClick={() => {
                            if (bonus.link) {
                              window.open(bonus.link, "_blank").focus();
                            }
                          }}
                        >
                          {bonus.photo && (
                            <CardMedia
                              component="img"
                              height="140"
                              image={bonus.photo}
                            />
                          )}
                          <CardContent>
                            <Typography variant="body1">
                              {bonus.comments}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        {complete &&
                          !bonus.used &&
                          bonus.identity === user.sub && (
                            <CardActions>
                              <Button
                                variant="outlined"
                                fullWidth={true}
                                onClick={() => {
                                  setComplete(false);
                                }}
                              >
                                Edit
                              </Button>
                            </CardActions>
                          )}
                      </Card>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return null;
  }
}
