import React, { useContext, useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { Grid, Typography, Paper, Link } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getChallenges,
  getBonusChallenges,
  setBusy,
} from "../../store/actions";
import { State } from "../../store/state";
import {
  FitnessCenter as LiftIcon,
  DirectionsRun as RunIcon,
  DirectionsWalk as WalkIcon,
  Pool as SwimIcon,
  DirectionsBike as BikeIcon,
  Rowing as RowIcon,
  Hiking as HikeIcon,
  AccessibilityNew as BurpeeIcon,
  AirlineSeatLegroomNormal as WallSitIcon,
  AirlineSeatReclineExtra as SitupIcon,
  Park as PlankIcon,
  FitnessCenter as SquatIcon,
  Landscape as MountainClimberIcon,
  AirlineSeatFlatAngled as PushupIcon,
  SportsMartialArts as LungeIcon,
  SelfImprovement as YogaIcon,
  AllInclusive as AllIcon,
  SwitchAccessShortcutAdd as OtherIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import * as consts from "../../consts";

export default function Main() {
  const { state, dispatch } = useContext(State);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setBusy(true, dispatch);
    getChallenges(state.year, getAccessTokenSilently, dispatch).then(() => {
      getBonusChallenges(state.year, getAccessTokenSilently, dispatch).then(
        () => {
          setBusy(false, dispatch);
        }
      );
    });
  }, []);

  const ChallengePaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: "center",
  }));

  const ChallengePanel = (props) => {
    let fontSize = props.activities.length > 3 ? "medium" : "large";
    let paperStyle = {
      height: "100%",
      borderColor: props.active ? "inherit" : "primary",
      color: props.active ? "inherit" : "primary",
      position: "relative",
    };
    let completeStyle = {
      position: "absolute",
      right: "4px",
      top: "4px",
    };
    return (
      <Grid item xs={6} sm={4} md={3}>
        <Link
          component={NavLink}
          to={`challenges/${props.link}`}
          sx={{
            textDecoration: "none",
          }}
        >
          <ChallengePaper variant="outlined" sx={paperStyle}>
            {props.status == "Complete" ? (
              <Typography component="div" sx={completeStyle}>
                <img
                  src="https://app.squamish.fit/checkmark.png"
                  width="50px"
                />
              </Typography>
            ) : props.active ? (
              <Typography component="div" variant="caption" sx={completeStyle}>
                {props.status}
              </Typography>
            ) : null}
            <Typography variant="body1">{props.label}</Typography>
            {props.activities.indexOf(consts.ACTIVITY_ALL) > -1 && (
              <AllIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_SWIM) > -1 && (
              <SwimIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_BIKE) > -1 && (
              <BikeIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_WALK) > -1 && (
              <WalkIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_HIKE) > -1 && (
              <HikeIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_RUN) > -1 && (
              <RunIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_ROW) > -1 && (
              <RowIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_LIFT) > -1 && (
              <LiftIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_BURPEE) > -1 && (
              <BurpeeIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_WALLSITES) > -1 && (
              <WallSitIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_PLANK) > -1 && (
              <PlankIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_SITUPS) > -1 && (
              <SitupIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_PUSHUPS) > -1 && (
              <PushupIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_SQUATS) > -1 && (
              <SquatIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_MOUNTAINCLIMBERS) >
              -1 && <MountainClimberIcon fontSize={fontSize} />}
            {props.activities.indexOf(consts.ACTIVITY_LUNGES) > -1 && (
              <LungeIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_YOGA) > -1 && (
              <YogaIcon fontSize={fontSize} />
            )}
            {props.activities.indexOf(consts.ACTIVITY_OTHER) > -1 && (
              <OtherIcon fontSize={fontSize} />
            )}
          </ChallengePaper>
        </Link>
      </Grid>
    );
  };

  const BonusChallengePanel = (props) => {
    let paperStyle = {
      borderColor: props.active ? "inherit" : "primary",
      color: props.active ? "inherit" : "primary",
      position: "relative",
    };
    return (
      <Grid item xs={12}>
        <Link
          component={NavLink}
          to={`bonuses/${props.link}`}
          sx={{
            textDecoration: "none",
          }}
        >
          <ChallengePaper variant="outlined" sx={paperStyle}>
            <Typography variant="body1">{props.title}</Typography>
            <Typography variant="caption">{props.summary}</Typography>
          </ChallengePaper>
        </Link>
      </Grid>
    );
  };

  return (
    <Grid item container xs={12} spacing={2}>
      {state.bonusChallenges?.map((bonusChallenge) => {
        return (
          <BonusChallengePanel
            key={bonusChallenge.id}
            active={bonusChallenge.active}
            title={bonusChallenge.title}
            summary={bonusChallenge.summary}
            link={`${bonusChallenge.year}/${bonusChallenge.id}`}
          />
        );
      })}
      {state.challenges?.map((challenge) => {
        return (
          <ChallengePanel
            key={challenge.id}
            active={challenge.active}
            label={challenge.label}
            link={`${challenge.year}/${challenge.id}`}
            activities={challenge.activities}
            status={challenge.status}
          />
        );
      })}
      <Grid item xs={12}>
        <Link
          component={NavLink}
          to={`leaderboard`}
          sx={{
            textDecoration: "none",
          }}
        >
          <ChallengePaper variant="outlined" sx={{ marginBottom: "16px" }}>
            <Typography variant="body1">Leaderboard</Typography>
          </ChallengePaper>
        </Link>
      </Grid>
    </Grid>
  );
}
