import React, { useEffect, useContext } from "react"; // eslint-disable-line no-unused-vars
import { useAuth0 } from "@auth0/auth0-react";
import {
  Backdrop,
  CircularProgress,
  ScopedCssBaseline,
  Paper,
  Grid,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { State } from "../store/state";
import { dismissAlert } from "../store/actions";
import { useLocation, useRoutes } from "react-router-dom";
import * as consts from "../consts";

import Alert from "./presentational/alert";
import Breadcrumb from "./navigation/breadcrumb";
import Header from "./navigation/header";
import Login from "./controls/login";

const App = (props) => {
  const theme = createTheme(props.theme);
  const { state, dispatch } = useContext(State);
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const routes = useRoutes(consts.ROUTES);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline sx={{ height: "100%" }}>
        <Backdrop open={state.busy}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {state.alerts.length > 0 && (
          <Alert
            alert={state.alerts[0]}
            onDismiss={() => {
              dismissAlert(dispatch);
            }}
          />
        )}
        <Header />
        <Paper sx={{ padding: theme.spacing(1), height: "100%" }}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Breadcrumb />
            </Grid>
            <Grid item xs={12}>
              {isAuthenticated ? routes : <Login />}
            </Grid>
          </Grid>
        </Paper>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};
export default App;
