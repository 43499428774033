import React from "react"; // eslint-disable-line no-unused-vars
import { Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import * as consts from "../../consts";

export default function Alert(props) {
  if (props.alert) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={props.alert ? true : false}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          } else {
            props.onDismiss();
          }
        }}
        message={props.alert.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                props.onDismiss();
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  } else {
    return null;
  }
}
