import React, { useContext } from "react"; // eslint-disable-line no-unused-vars
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { NavLink, useMatch } from "react-router-dom";
import * as consts from "../../consts";
import { State } from "../../store/state";
import { useAuth0 } from "@auth0/auth0-react";

export default function Breadcrumb(props) {
  const { state, dispatch } = useContext(State);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const isHome = useMatch("/");
  const isBonus = useMatch("bonuses/*");
  const isChallenge = useMatch("challenges/*");
  let breadcrumbs = [];
  consts.ROUTES.map((route) => {
    if (useMatch(route.path)) {
      breadcrumbs.push(route);
    }
  });

  if (!isHome && isAuthenticated) {
    return (
      <Breadcrumbs>
        {breadcrumbs.reverse().map((breadcrumb, index) => {
          const last = index === breadcrumbs.length - 1;
          return last ? (
            <Typography key={index}>{breadcrumb.label}</Typography>
          ) : (
            <Link key={index} component={NavLink} to={breadcrumb.link}>
              {breadcrumb.label}
            </Link>
          );
        })}
        {state.challenge && isChallenge && (
          <Typography>{state.challenge.label}</Typography>
        )}
        {state.bonusChallenge && isBonus && (
          <Typography>{state.bonusChallenge.title}</Typography>
        )}
      </Breadcrumbs>
    );
  } else {
    return null;
  }
}
